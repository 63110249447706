import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function CarContainer() {
    return (
      <section>
        <Container fluid className="home-section" id="home">
          <Container className="home-content">
            <Row>
              <Col md={7} className="home-header">
                Car Container JS
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
    );
  }
  
  export default CarContainer;
  