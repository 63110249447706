import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Welcome to Ibanez Autos",
          "Quality pre-owned vehicles",
          "Mobile auto repair",
          "Affordable prices",
          "Hablamos Espansol!"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 33,
      }}
    />
  );
}

export default Type;
