import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import Type from "./Type";

function Home() {
  const defaultProps = {
    center: {
      lat: 31.453533433634817,
      lng: -97.06623673439027
    },
    zoom: 17
  };

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
            <Row className="home-content-container" >
              <Col md={12} className="home-header">
                <Type/>
              </Col>
            </Row>
            <Row className="home-locate-us">
              <Col md={7} className="home-locate-us-text">
                <h4> Welcome! </h4>
                <p> We are located at 9589 S 3rd St Rd, Waco, TX 76706</p>
                <p> Feel free to give us a cal at <a href="tel:2547169745">(254)716-9745</a> </p>
                <p> Or send us an email at <a href="mailto:ibanezsg03@gmail.com"> ibanezsg03@gmail.com</a> </p>
              </Col>
              <Col md={5} className="home-locate-ua-map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyAjgf1wTNRHYLOcSej4eJaL0kwYVPd1dX-c" }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                />
              </Col>
            </Row>

          </Container>

      </Container>
    </section>
  );
}

export default Home;
